.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}
